<template>
  <div style="width: 100%; margin: 0">
    <v-row>
      <v-col class="d-flex align-center justify-center" style="gap: 16px">
        <EmptyComponent :empty="processWithNoDocuments">
          <p v-if="documentsLoading" class="pb-0 mb-4 text-center">
            Cargando documentos...
          </p>
          <ArbolDocumentosProceso :arbol="listadoDocumentos">
            <template v-slot:documentos="{ documentos, tipo }">
              <DataTableComponent
                class="my-4"
                :headers="headers"
                :items="documentos"
                item-key="id"
                :tiene_paginacion="false"
                dense
                no-gutters
                :getRowClass="getRowClass"
              >
                <!-- Slot de acciones -->
                <template v-slot:[`item.nombre_documento`]="{ item }">
                  {{ item.nombre_documento }}
                  {{ item.deleted_at ? "(Eliminado)" : "" }}
                </template>
                <template v-slot:[`item.user`]="{ item }">
                  <template
                    v-if="
                      item['Usuario?.Empleado.Persona.nombre'] &&
                      item['Usuario?.Empleado.Persona.apellido']
                    "
                  >
                    {{ item["Usuario?.Empleado.Persona.nombre"] }}
                    {{ item["Usuario?.Empleado.Persona.apellido"] }}
                  </template>
                  <span v-else>-</span>
                </template>
                <template v-slot:[`item.created_at`]="{ item }">
                  {{ formatDate(item.created_at) }}
                </template>
                <template v-slot:[`item.acciones`]="{ item }">
                  <div
                    class="d-flex justify-center align-center"
                    style="gap: 8px"
                  >
                    <!-- <v-btn icon @click="downloadFileFtn(item)">
                      <v-icon>mdi-download</v-icon>
                    </v-btn> -->
                    <v-icon
                      v-if="item.ruta_documento"
                      small
                      class="ml-1"
                      color="primary"
                      @click.stop="loadAttachmentPdf(item)"
                    >
                      mdi-file-eye
                    </v-icon>
                  </div>
                </template>
              </DataTableComponent>
            </template>
          </ArbolDocumentosProceso>
        </EmptyComponent>

        <PdfModal
          :isOpen="isPdfModalOpen"
          :source-loadable="pdfLoadable"
          @on-visibility-change="handlePdfModalVisibility"
          :filename="filename"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import moment from "moment";
import {
  createLoadable,
  toggleLoadable,
  setLoadableResponse,
} from "@/utils/loadable";
import { getFilenameFromPath } from "@/utils/data";
import { EmptyComponent } from "@/components/utils";
import ArbolDocumentosProceso from "@/views/ProcesoCompraDoc/components/ArbolDocumentosProceso.vue";
import DataTableComponent from "@/components/DataTableComponent.vue";
import PdfModal from "@/components/PdfModal.vue";

export default {
  name: "DocumentosProcesoComponent",
  components: {
    ArbolDocumentosProceso,
    DataTableComponent,
    EmptyComponent,
    PdfModal,
  },
  computed: {
    idProceso() {
      return this.$route.params.id_proceso;
    },
    processWithNoDocuments() {
      return !this.documentsLoading && this.listadoDocumentos.length === 0;
    },
    headers() {
      return [
        {
          align: "center",
          text: "Documento",
          value: "nombre_documento",
        },
        {
          align: "center",
          text: "Subido por",
          value: "user",
        },
        {
          align: "center",
          text: "Fecha de subida",
          value: "created_at",
        },
        {
          align: "center",
          text: "Acciones",
          value: "acciones",
          sortable: false,
        },
      ];
    },
  },
  data() {
    return {
      documentsLoading: false,
      listadoDocumentos: [],
      pdfLoadable: createLoadable(null),
      activePath: "",
      filename: "",
      isPdfModalOpen: false,
    };
  },
  methods: {
    formatDate(date) {
      return moment(date).format("DD-MM-YYYY HH:mm A");
    },
    getRowClass(item) {
      if (item.deleted_at) {
        return "highlight-row";
      }
      return "";
    },
    async obtenerListadoDocumentos() {
      try {
        this.documentsLoading = true;
        const { status, data } =
          await this.services.PacProcesos.getListDocuments(this.idProceso);
        if (status === 200) {
          this.listadoDocumentos = data?.documentos ?? [];
        }
      } catch (error) {
        this.pushAppMessage({
          message: "Error al cargar los documentos.",
          type: "error",
        });
      } finally {
        this.documentsLoading = false;
      }
    },
    async downloadFileFtn(item) {
      const response =
        await this.services.PacProcesos.descargarDocumentoProceso({
          ruta: item.ruta_documento,
          disk: item.disk,
        });
      if (!response) {
        this.temporalAlert({
          show: true,
          message: "Ocurrió un error al descargar el documento",
          type: "error",
        });

        return;
      }

      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });

      const file = new File([blob], item.nombre_documento, {
        type: response.headers["content-type"],
      });

      const link = document.createElement("a");
      link.setAttribute("download", item.nombre_documento);
      link.href = window.URL.createObjectURL(file);
      link.download = "";
      link.click();
    },
    async loadAttachmentPdf(item) {
      this.isPdfModalOpen = true;

      try {
        console.log("item", item);

        if (this.activePath === item.ruta_documento) return;

        this.filename = getFilenameFromPath(item.ruta_documento);
        this.activePath = item.ruta_documento;
        toggleLoadable(this.pdfLoadable);
        const { data } =
          await this.services.PacProcesos.descargarDocumentoProceso({
            ruta: item.ruta_documento,
            disk: item.disk,
          });

        setLoadableResponse(this.pdfLoadable, data, { isFile: true });
      } catch (error) {
        this.isPdfModalOpen = false;
      }
    },
    handlePdfModalVisibility(visible) {
      this.isPdfModalOpen = visible;
    },
  },
  created() {
    this.obtenerListadoDocumentos();
  },
};
</script>
