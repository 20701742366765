<template>
  <v-container fluid>
    <v-card>
      <v-card-title>
        <div class="d-flex align-center" style="gap: 16px">
          <v-breadcrumbs large :items="itemsBreadcrumbs"></v-breadcrumbs>
        </div>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <v-row no-gutters class="d-flex align-center">
          <v-col cols="12" sm="2">
            <v-img
              width="150px"
              class="mx-auto"
              src="@/assets/img/logo_512_negro.png"
            />
          </v-col>
          <v-col cols="12" sm="12" md="10" class="text-left">
            <h4 class="text-h6 text-sm-h4">
              {{ infoProceso?.nombre_proceso }}
            </h4>
            <p
              v-if="cdfProceso?.validacion"
              class="secondary--text body-2 mb-0 mt-4"
            >
              <v-icon color="success">mdi-check-decagram</v-icon>
              Fondo de proceso certificados
            </p>
            <p
              v-if="!cdfProceso?.validacion"
              class="secondary--text body-2 mb-0 mt-4"
            >
              <v-icon color="gray">mdi-alert-decagram</v-icon>
              Se han certificado
              <b>{{
                toMoneyFormat(cdfProceso?.cantidades?.monto_certificado)
              }}</b>
              de
              <b>{{
                toMoneyFormat(cdfProceso?.cantidades?.monto_requerido)
              }}</b>
            </p>
          </v-col>
        </v-row>
        <v-tabs v-model="tab" show-arrows flat center-active>
          <v-tab v-for="t in tabs" :key="t.id" class="secondary--text">
            <v-icon size="28px !important" class="mr-2">
              {{ t.icon }}
            </v-icon>
            <span class="text-none">{{ t.title }}</span>
          </v-tab>
          <v-tab-item v-for="t in tabs" :key="t.id">
            <div style="background-color: white" class="px-4 py-4">
              <component :is="t.component" />
            </div>
          </v-tab-item>
        </v-tabs>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import InfoGeneralComponent from "./components/InfoGeneralComponent.vue";
import PipComponent from "./components/PipComponent.vue";
import DocumentosProcesoComponent from "./components/DocumentosProcesoComponent.vue";
import { toMoneyFormat } from "@/utils/data";

export default {
  name: "PACSubastaDetalleView",
  components: {
    InfoGeneralComponent,
  },
  data() {
    return {
      tab: 0,
      infoProceso: {},
      cdfProceso: {
        cantidades: null,
        validacion: null,
      },
    };
  },
  computed: {
    ...mapState("procesoCompraDoc", ["procesoData"]),
    tabs() {
      return [
        {
          id: 1,
          icon: "mdi-file-cog",
          title: "General",
          component: InfoGeneralComponent,
        },
        {
          id: 2,
          icon: "mdi-format-list-bulleted",
          title: "Plan de implementación del proceso",
          component: PipComponent,
        },
        {
          id: 3,
          icon: "mdi-file-document-multiple",
          title: "Documentos del proceso",
          component: DocumentosProcesoComponent,
        },
      ];
    },
    idProceso() {
      return this.$route.params.id_proceso;
    },
    itemsBreadcrumbs() {
      return [
        {
          text: "Procesos de compra subasta",
          disable: false,
          href: "/procesos-compra-subasta",
        },
        {
          text: `${this.infoProceso?.codigo_proceso} / ${
            this.infoProceso?.codigo_interno || ""
          }`,
          disable: true,
        },
      ];
    },
  },
  methods: {
    ...mapMutations("procesoCompraDoc", ["setInfoProceso"]),
    toMoneyFormat,
    async obtenerProceso() {
      try {
        const response = await this.services.PacProcesos.getProceso(
          this.idProceso
        );
        if (response.status === 200) {
          this.setInfoProceso(response.data);
          this.infoProceso = {
            ...JSON.parse(JSON.stringify(response.data)),
          };
        }
      } catch (error) {
        this.$router.replace({ name: "dashboard" });
      }
    },
    async verificarCdfProceso() {
      const { status, data } =
        await this.services.PacProcesos.verificarCdfProceso(this.idProceso);

      if (status === 200) {
        this.cdfProceso.cantidades = {
          ...data,
        };

        if (
          data?.monto_certificado !== null &&
          data?.monto_requerido !== null
        ) {
          this.cdfProceso.validacion =
            data?.monto_certificado >= data?.monto_requerido;
        }
      }
    },
    async initData() {
      await this.obtenerProceso();
      await this.verificarCdfProceso();
    },
  },
  async created() {
    await this.initData();
  },
};
</script>
