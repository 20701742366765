<template>
  <div style="width: 100%; margin: 0">
    <v-row>
      <v-col>
        <v-card style="height: 100%">
          <v-card-title class="d-flex justify-space-between align-center">
            <div class="d-flex align-center flex-wrap" style="gap: 4px">
              <v-icon style="transform: translateY(-2px)"
                >mdi-information-variant-circle-outline</v-icon
              >
              <span
                class="font-weight-medium text-subtitle-1"
                style="word-break: word-break"
              >
                Información
              </span>
            </div>
            <v-chip
              :color="infoProceso?.SeguimientoProceso?.color"
              style="display: block"
              class="white--text"
              label
            >
              {{ infoProceso?.SeguimientoProceso?.nombre }}
            </v-chip>
          </v-card-title>

          <v-divider />

          <v-card-text>
            <div class="px-4 py-4">
              <dl>
                <dt class="font-weight-bold text-body-2">
                  Nombre del proceso:
                </dt>
                <dd>{{ infoProceso?.nombre_proceso }}</dd>

                <dt class="font-weight-bold mt-4 text-body-2">
                  Modalidad de compra:
                </dt>
                <dd>{{ infoProceso?.FormaContratacion?.nombre }}</dd>

                <dt class="font-weight-bold mt-4 text-body-2">
                  Mes/año inicio del proceso:
                </dt>
                <dd>{{ fechaInicioProceso }}</dd>

                <dt class="font-weight-bold mt-4 text-body-2">
                  Mes/año posible contratación:
                </dt>
                <dd>{{ fechaPosibleContratacion }}</dd>

                <template v-if="infoProceso?.comentario">
                  <dt class="font-weight-bold mt-4 text-body-2">
                    Comentario sobre la Modificativa/Prórroga:
                  </dt>
                  <dd>{{ infoProceso?.nombre_proceso }}</dd>
                </template>
              </dl>
            </div>

            <v-btn
              v-if="documentos.length > 0"
              color="secondary"
              @click="descargarExpediente"
            >
              <v-icon color="white">mdi-download</v-icon>
              Descargar expediente de proceso
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col v-if="[5, 80, 81].includes(infoProceso?.SeguimientoProceso?.id)">
        <ContratosGanadores />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
import ContratosGanadores from "../../ProcesoCompraDoc/components/ContratosGanadores.vue";

export default {
  name: "InfoGeneralComponent",
  components: {
    ContratosGanadores,
  },
  data() {
    return {
      documentos: [],
      documento_cargado: null,
    };
  },
  computed: {
    ...mapState("procesoCompraDoc", ["procesoData"]),
    idProceso() {
      return this.$route.params.id_proceso;
    },
    infoProceso() {
      return {
        ...JSON.parse(JSON.stringify(this.procesoData)),
      };
    },
    fechaInicioProceso() {
      return moment(this.infoProceso?.fecha_inicio_proceso).format("MM-YYYY");
    },
    fechaPosibleContratacion() {
      return moment(this.infoProceso?.fecha_posible_contratacion).format(
        "MM-YYYY"
      );
    },
  },
  methods: {
    async obtenerExpedienteProceso() {
      const { status, data } =
        await this.services.PacProcesos.obtenerDocumentosProceso(
          this.idProceso,
          5000
        );

      if (status === 200) {
        this.documentos = data;
        this.documento_cargado = this.documentos[0]?.ruta_documento;
      }
    },
    async descargarExpediente() {
      const response =
        await this.services.PacProcesos.descargarDocumentoProceso({
          ruta: this.documento_cargado,
        }).catch(() => {});

      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });

      const file = new File([blob], this.documentos[0].nombre_documento, {
        type: response.headers["content-type"],
      });

      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(file);
      link.download = "";
      link.click();
    },
  },
  created() {
    this.obtenerExpedienteProceso();
  },
};
</script>
