<template>
  <PipProcesoComponent hideActions :etapas="infoProceso?.EtapaPorProcesos" />
</template>
<script>
import PipProcesoComponent from "../../ProcesoCompraDoc/components/PipProcesoComponent.vue";
import { mapState } from "vuex";

export default {
  name: "PipComponent",
  components: {
    PipProcesoComponent,
  },
  computed: {
    ...mapState("procesoCompraDoc", ["procesoData"]),
    infoProceso() {
      return {
        ...JSON.parse(JSON.stringify(this.procesoData)),
      };
    },
  },
};
</script>
